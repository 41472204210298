<template>
  <div>
    <template v-if="group">
      <custom-breadcrumb
        :pageTitle="$t('forms.edit_model', {model: $t('models.group')})"
        :breadcrumb="breadcrumb"
      ></custom-breadcrumb>
      <b-row>
        <b-col>
          <b-card>
            <b-tabs v-model="tabIndex" v-if="group">
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="InfoIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('models.group')}}</span>
                </template>
                <tab-main
                  :school="school"
                  :group="group"
                  @refetch-data="getData(group.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

              <!-- Users -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="UserIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('forms.linked_teachers')}}</span>
                </template>
                <tab-users
                  :school="school"
                  :group="group"
                  @refetch-data="getData(group.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

              <!-- Dreamers -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="SmileIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('Dreamers')}}</span>
                </template>
                <tab-dreamers
                  :school="school"
                  :group="group"
                  @refetch-data="getData(group.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

              <!-- Customización -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="SlidersIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('customize.customization')}}</span>
                </template>
                <customization-component
                  :dreamers="[group.id]"
                  :isGroup="true"
                  @refetch-data="getData(group.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

              <!-- CONTENT RESTRICTION -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="ClockIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('customize.mandatory')}}</span>
                </template>
                <content-restriction-component
                  :info="[group]"
                  :dreamers="[group.id]"
                  :isGroup="true"
                  @refetch-data="getData(group.id)"
                  class="mt-2 pt-75"
                />
              </b-tab>

              <!-- Children Password -->
              <b-tab>
                <template #title>
                  <feather-icon
                    icon="UnlockIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">{{$t('children_passwords.edit_children_passwords')}}</span>
                </template>
                  <children-passwords-components 
                    :isGroup="true"
                    :info="[group]"
                    :dreamers="[group.id]"
                    @refetch-data="getData(group.id)"
                    class="mt-2 pt-75"
                  />
              </b-tab>

            </b-tabs>
          </b-card>
        </b-col>
      </b-row>

    </template>
    <template v-else>
      <b-alert
        variant="danger"
        :show="group === undefined"
      >
        <h4 class="alert-heading">
          Error fetching group data
        </h4>
        <div class="alert-body">
          No group found with this id. Go back to
          <b-link
            class="alert-link"
            :to="{ name: 'schools'}"
          >
            Schools List
          </b-link>
        </div>
      </b-alert>
    </template>
  </div>
</template>

<script>
import { fetchSchoolGroup } from '@/api/routes'
import router from '@/router'
import TabMain from './edit/TabMain.vue'
import TabUsers from './edit/TabUsers.vue'
import TabDreamers from './edit/TabDreamers.vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'

import CustomizationComponent from '@/views/sl-components/CustomizationComponent.vue'
import ContentRestrictionComponent from '@/views/sl-components/ContentRestrictionComponent.vue'
import ChildrenPasswordsComponents from "@/views/sl-components/ChildrenPasswordsComponents.vue";

import {
  BTab, BTabs, BCard, BAlert, BLink,
  BRow, BCol
} from 'bootstrap-vue'
export default {
  components:{
    ChildrenPasswordsComponents,
    CustomizationComponent,
    ContentRestrictionComponent,
    CustomBreadcrumb,
    TabDreamers,
    TabUsers,
    TabMain,
      BTab, BTabs, BCard, BAlert, BLink,BRow, BCol

  },
  data(){
    return {
      school: {},
      group:null,
      tabIndex:null,
    }
  },
  computed:{
    breadcrumb(){
      if(this.$store.getters['user/isSchool']){
        return [
          {
            text: this.$t('Schools'),
          },
          {
            text: this.school.name
          },
          {
            to: 'school-groups-list',
            text: this.$t('Groups')
          },
          {
            text: this.$t('forms.edit_model', {model: this.$t('models.group')}),
            active: true,
          },
        ] 
      } else {
        return  [
          {
            text: this.$t('Schools'),
            to: 'schools'
          },
          {
            to: 'school-view',
            params: {
              id: this.school.id,
            },
            text: this.school.name
          },
          {
            to: 'school-groups-list',
            text: this.$t('Groups')
          },
          {
            text: this.$t('forms.edit_model', {model: this.$t('models.group')}),
            active: true,
          },
        ]            
      }
    }
  },
  methods:{
    async getData(id){
        // console.log("loading")
        let data = {
          'id': id,
          'school_id': router.currentRoute.params.id
        }
        await this.$http.post(fetchSchoolGroup, data).then( response =>{
          // console.log(response)
          if(response.data.status == 200) {
            this.school = response.data.school;
            this.group = response.data.group;
            
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
    },
  },
  mounted(){
    this.id = router.currentRoute.params.group_id;
    this.getData(this.id)
    // console.log(router.currentRoute.params.tab)
    this.tabIndex = router.currentRoute.params.tab || 0;
  },
}
</script>

<style>

</style>